import { FC, ReactElement, useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/navigation'
import IconPlus from '@haiper/icons-svg/icons/outline/plus-small.svg'
import IconEdit from '@haiper/icons-svg/icons/outline/edit.svg'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import Button from '@/components/button'
import EditCollectionDialog from '@/components/edit-collection-dialog'
import useActivePlan from '@/hooks/useActivePlan'
import Dialog from '@/components/dialog'
import { Collection, Collection as CollectionType } from '@/types'
import { cls } from '@/utils'
import { useCachedCollections } from '@/hooks/useCollections'

interface CollectionPickerProps {
  value?: string
  onChange: (v: string) => void
  className?: string
  triggerClassName?: string
  trigger?: ReactElement
  hideDropdownIcon?: boolean
  showEdit?: boolean
  emptyCollectionName?: string
}

const CollectionPicker: FC<CollectionPickerProps> = ({
  value,
  onChange,
  triggerClassName,
  className,
  showEdit,
  hideDropdownIcon,
  trigger,
  emptyCollectionName = 'All',
}: CollectionPickerProps) => {
  const [collectionDialog, setCollectionDialog] = useState(false)
  const [needMemberOpen, setNeedMemberOpen] = useState(false)
  const [selectOpen, setSelectOpen] = useState(false)
  const { data: collectionList, refresh: refreshCollectionList } = useCachedCollections()
  const [isEdit, setIsEdit] = useState(false)
  const [editingCollection, setEditingCollection] = useState<CollectionType | null>(null)
  const router = useRouter()
  const { data: activePlan, loading: activePlanLoading } = useActivePlan()
  const isFreePlan = (activePlan?.is_free || !activePlan) && !activePlanLoading

  const openCollectionDialog = useCallback(
    (e?: any) => {
      e?.preventDefault?.()
      e?.stopPropagation?.()

      setIsEdit(false)
      setEditingCollection(null)
      setSelectOpen(false)
      if (isFreePlan) {
        setNeedMemberOpen(true)
      } else {
        setCollectionDialog(true)
      }
    },
    [isFreePlan],
  )

  const gotoMembership = useCallback(() => {
    router.push('/membership')
  }, [router])

  const { data: collections = [], refresh: refreshCollections } =
    useCachedCollections()

  const onCreateSuccess = useCallback(async () => {
    setCollectionDialog(false)
    refreshCollections()
  }, [setCollectionDialog, refreshCollections])

  const editCollection = useCallback(() => {
    const collection = collections?.find((item) => item.collection_id === value)
    if (collection) {
      setEditingCollection(collection)
      setIsEdit(true)
    }
    setCollectionDialog(true)
  }, [collections, value])

  const deleteCollection = useCallback(() => {
    onChange('all')
    setCollectionDialog(false)
    refreshCollections()
  }, [onChange, refreshCollections])

  const handleChange = useCallback(
    (v: string) => {
      onChange?.(v)
      setSelectOpen(false)
    },
    [onChange],
  )

  return (
    <div className={cls('flex', value === 'all' ? '' : 'gap-2')}>
      <Select
        open={selectOpen}
        value={value ?? 'all'}
        onValueChange={handleChange}
        onOpenChange={setSelectOpen}
      >
        <SelectTrigger
          className={triggerClassName}
          hideDropdownIcon={hideDropdownIcon}
        >
          {trigger}
        </SelectTrigger>
        <SelectContent className={cls('border px-0 w-full', className)}>
          <div className='px-1'>
            <SelectItem
              className='cursor-pointer hover:bg-surface-hover focus:bg-surface-hover focus:text-text'
              value='all'
            >
              {emptyCollectionName}
            </SelectItem>
          </div>
          <div className='h-[1px] bg-border my-1'></div>
          <div className='px-1'>
            <Button
              variant='transparent'
              className='w-full justify-start pl-3 gap-1 h-9'
              data-component='creation-input'
              data-outside='false'
              onClick={openCollectionDialog}
            >
              <IconPlus className='text-icon' />
              <span className='px-1 font-medium'>Create new collection</span>
            </Button>
          </div>
          {collectionList?.length ? <div className='h-[1px] bg-border my-1'></div> : null}
          {collectionList?.map((item: Collection) => (
            <div key={item.collection_id} className='px-1'>
              <SelectItem
                className='cursor-pointer hover:bg-surface-hover focus:bg-surface-hover focus:text-text overflow-hidden text-ellipsis'
                value={item.collection_id}
              >
                <div
                  className={cls(
                    'w-full overflow-hidden text-ellipsis',
                    value === 'all' ? 'max-w-calc-100vw-minus-64px' : 'max-w-calc-100vw-minus-134px pr-8',
                  )}
                >
                  {item.name}
                </div>
              </SelectItem>
            </div>
          ))}
        </SelectContent>
      </Select>
      {['all', 'default'].includes(value || '') || !showEdit ? null : (
        <Button
          variant='outline'
          className='rounded-full px-2 border-b-2'
          onClick={editCollection}
        >
          <IconEdit className='text-icon' />
        </Button>
      )}
      <EditCollectionDialog
        open={collectionDialog}
        isEdit={isEdit}
        editingCollection={editingCollection}
        onOpenChange={(v) => setCollectionDialog(v)}
        onCreateSuccess={onCreateSuccess}
        onDeleteCollection={deleteCollection}
      />
      <Dialog
        title='Membership required'
        open={needMemberOpen}
        childrenClassName='min-h-5 mt-1'
        footer={
          <Button variant='primary' className='w-full mt-8' onClick={gotoMembership}>
            Upgrade
          </Button>
        }
        onOpenChange={(v) => setNeedMemberOpen(v)}
      >
        You need to upgrade to create collections.
      </Dialog>
    </div>
  )
}

export default CollectionPicker
