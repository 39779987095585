import Button from '@/components/button'
import { cls, whisper } from '@/utils'
import { useAtom } from 'jotai'
import { creationInputCollectionIdsAtom } from '@/atoms'
import IconFolderOutline from '@haiper/icons-svg/icons/outline/folder-add.svg'
import IconFolderSolid from '@haiper/icons-svg/icons/solid/folder.svg'
import { useCallback, useMemo } from 'react'
import CollectionPicker from '@/components/collection-picker'

export interface CollectionSettingsProps {
  className?: string
}

export default function CollectionSettings({
  className,
}: CollectionSettingsProps) {
  const [collectionIds, setCollectionIds] = useAtom(
    creationInputCollectionIdsAtom,
  )

  const collectionChange = useCallback(
    (v: string) => {
      whisper('collectionChange.v is: ', v)
      setCollectionIds(!v || v === 'all' ? [] : [v])
    },
    [setCollectionIds],
  )

  const collection = useMemo(() => {
    return collectionIds[0] ?? null
  }, [collectionIds])

  const hasCollections = collectionIds.length > 0
  const Icon = hasCollections ? IconFolderSolid : IconFolderOutline
  const iconClassname = hasCollections ? 'text-text-interactive' : 'text-icon'

  return (
    <CollectionPicker
      hideDropdownIcon
      value={collection}
      triggerClassName='border-0 border-none p-0'
      emptyCollectionName='Not selected'
      trigger={
        <Button
          variant='outline'
          aria-label='Visibility Setting'
          type='button'
          tooltip='Add to Collection'
          className={cls(
            'my-0 p-0 shrink-0 w-8 h-8 rounded-md inline-flex items-center justify-center text-icon',
            className,
          )}
        >
          <Icon className={cls('text-icon size-5', iconClassname)} />
        </Button>
      }
      onChange={collectionChange}
    />
  )
}
